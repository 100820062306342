export const validateEmail = (email) => {
  const isValid = new RegExp('.{1,}@[^.]{1,}', 'gi').test(email);
  return isValid;
};

export const getMessageContent = async (event) => {
  try {
    return {
      type: 'text',
      value: event,
    };
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const setLocalStorage = (keyName, keyValue) => {
  if (keyName === 'ttl') {
    const ttl = { ttl: new Date().getTime() + 1 * 60 * 60 * 1000 };
    window.localStorage.setItem('ttl', JSON.stringify(ttl));
  }
  const data = {
    metadata: keyValue,
    ttl: new Date().getTime() + 1 * 60 * 60 * 1000,
  };

  window.localStorage.setItem(keyName, JSON.stringify(data));
};

export const getTTL = (keyName) => {
  const data = window.localStorage.getItem(keyName);
  if (!data) return null;

  const item = JSON.parse(data);
  const TTL = new Date(item.ttl);
  const now = new Date().getTime();

  if (TTL <= now) {
    window.localStorage.clear();
    return true;
  } else {
    return false;
  }
};

export function parseMessage(inputText) {
  const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const emailRegex = /\b[\w.-]+@[\w.-]+\.\w{2,}\b/g;

  let linkedText = inputText?.replace(urlRegex, function (url) {
    if (url.startsWith('http') || url.startsWith('www')) {
      return `<a href="${url}" target="_blank">${url}</a>`;
    } else {
      return url;
    }
  });

  linkedText = linkedText?.replace(emailRegex, function (email) {
    return `<a href="mailto:${email}" target="_blank">${email}</a>`;
  });

  return linkedText;
}

export const messageTextTransform = (str) => {
  if (!str) return '';

  // Create a container to process text like html
  const parser = new DOMParser();
  const doc = parser.parseFromString(`<div>${str}</div>`, 'text/html');

  // Function to applay styles only for those text node, avoid links
  const applyFormatting = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      let text = node.nodeValue;

      // apply markdown styles
      text = text
        .replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, '<b>$1</b>')
        .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g, '<i>$1</i>')
        .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g, '<s>$1</s>')
        .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, '<u>$1</u>')
        .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g, '<tt>$1</tt>')
        .replace(/[\n\r]/g, '<br>');

      const span = document.createElement('span');
      span.innerHTML = text;
      node.replaceWith(span);
    } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName !== 'A') {
      Array.from(node.childNodes).forEach(applyFormatting);
    }
  };

  applyFormatting(doc.body);
  return doc.body.innerHTML;
};
