import gql from "graphql-tag";
import React, { useEffect } from 'react'
import { useQuery } from "react-apollo";
import { AppContext } from "../../context/app";


const GET_COMMUNICATION = gql`
    query Communication($id: ID) {
            communication(id: $id){
            id  
        }
    }  
`

export const CommnunicationStatus = () => {

    const context = React.useContext(AppContext);
    const { updateState, state } = context;
    const {communicationId} = state
 
    const { data, error, loading } = useQuery(GET_COMMUNICATION, {
        variables: {
            id: communicationId
        },
        pollInterval: 5 * 1000
    });
    useEffect(() => {
        if (data && !data.communication) {
            updateState({
                disableInput:true // if the comunication has ended, disable input
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (loading) {
        return null;
    }

    if (error) {
        return error
    }

    return null;
}