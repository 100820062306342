import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { get } from 'lodash';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';

import Chat from './components/Chat';
import Form from './components/Form';
import ApolloSetup from './components/Setup/ApolloSetup';
import IntlSetup from './components/Setup/IntlSetup';
import { AppContext } from './context/app';
import { useURLSearchParams } from './hooks';
import { ErrorBoundary } from './components/ErrorBoundary';
import { subscriptionClient } from './config/apollo'

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Monserrat', sans-serif",
    color: 'black',
  },
});

const App = (prop) => {
  const search = useURLSearchParams();
  const metadata =
    localStorage.getItem('session_metadata') || '{"metadata":{}}';

  const { name, contact, scid, token, communicationId } =
    JSON.parse(metadata).metadata;

  const fields = useMemo(() => {
    let fields = [];
    try {
      fields = JSON.parse(search.get('fields') || {});
    } catch (e) {}

    return fields.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.name]: curr,
      };
    }, {});
  }, [search]);

  const translations = useMemo(() => {
    let translations = {};

    try {
      translations = JSON.parse(search.get('translations') || {});
    } catch (e) {}

    return translations;
  }, [search]);

  const [isConnected, setIsConnected] = useState(true)
  const [error, setError] = useState()
  const [state, setState] = useState({
    initial: null,
    name,
    contact,
    scid,
    token,
    communicationId,
    question: '',
    tenantId: search.get('tenantId'),
    instance: search.get('instance'),
    locale: search.get('locale') || 'en',
    fields,
    translations,
    buttons: search.get('buttons'),
  });

  const updateState = useCallback((update) => {
    setState((s) => ({
      ...s,
      ...update,
    }));
  }, []);

  const resetState = () => {
    localStorage.clear();

    updateState({
      initial: true,
      question: '',
      scid: null,
      communicationId: null,
      disableInput:false,
     
    });
  };

  console.log('Initial', state.initial);

  useEffect(() => {
    const { scid, name, contact, token } = JSON.parse(metadata).metadata;

    updateState({
      initial: scid && token ? false : true,
      scid,
      name: get(fields, 'Name.value', name),
      contact: get(fields, 'Contact.value', contact),
      disableInput:false,
    });
  }, [updateState, fields, metadata]);

  const handleInputChange = (event) => {
    const {
      target: { value, name },
    } = event;
    const valueToTrim = value.trimStart();
    updateState({
      [name]: valueToTrim,
    });
  };

  useEffect(() => {
    if (!isConnected) {
      return subscriptionClient.onReconnected(() => {
        console.log('Reconnected')
        if (!isConnected) {
          setIsConnected(true)
          setError(undefined)
        }
      })
    }

    subscriptionClient.onDisconnected(() => {
      if (isConnected && error) {
        console.log('Disconnected')
        setIsConnected(false)
      }
    })
  }, [isConnected, error])

  const { initial } = state;

  if (initial === null) {
    return null;
  }

  const logo = search.get('logo');
  const agentLogo = search.get('agentLogo');
  const conversationLogo = search.get('conversationLogo');
  const headerTitle = search.get('headerTitle');
  const tabIcon =
    search.get('tabIcon') ?? 'https://testcs.w3spaces.com/q-logo.svg';

  return (
    <ErrorBoundary
      isConnected={isConnected}
      initial={initial}
      error={error}
      onError={setError}
    >
      <Fragment>
        {search.get('cssUrl') ? (
          <Helmet>
            <link href={`${search.get('cssUrl')}`} rel="stylesheet" />
            <link
              id="favicon"
              sizes="16x16"
              rel="icon"
              href={tabIcon}
              type="image/x-icon"
            />
          </Helmet>
        ) : (
          <Helmet>
            <link
              id="favicon"
              rel="icon"
              href="https://testcs.w3spaces.com/q-logo.svg"
              type="image/x-icon"
            />
          </Helmet>
        )}

        <ApolloSetup>
          <IntlSetup locale={state.locale} override={translations}>
            <AppContext.Provider
              value={{
                state,
                updateState,
                handleInputChange,
                resetState,
              }}
            >
              <ThemeProvider theme={theme}>
                {initial ? (
                  <Form logo={logo} />
                ) : (
                  <Chat
                    isConnected={isConnected}
                    headerTitle={headerTitle}
                    agentLogo={agentLogo}
                    conversationLogo={conversationLogo}
                  />
                )}
              </ThemeProvider>
            </AppContext.Provider>
          </IntlSetup>
        </ApolloSetup>
      </Fragment>
    </ErrorBoundary>
  );
};

export default App;
